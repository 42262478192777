// eslint-disable-next-line import/no-extraneous-dependencies
import Vue from 'vue';
import Vuetify from 'vuetify/lib';

Vue.use(Vuetify);

export default new Vuetify({
  options: {
    customProperties: true,
  },
  theme: {
    themes: {
      light: {
        primary: '#0067b3',
        secondary: '#FFFFFF',
        accent: '#fed401',
        error: '#EE1C25',
        success: '#EE1C25',
      },
    },
  },
  icons: {
    iconfont: 'mdiSvg',
    //
  },
});
